<template>
  <div id="PI">
    <transition name="load">
      <div class="loading" v-if="loading" key=1>
        <img src="../../assets/loading.gif" alt="" />
      </div>

      <div class="PI-inner" v-else key=2>
        <div class="PI-up">
          <h1>Informasi Tanaman</h1>
          <!-- <div class="PI-search"></div> !-->
        </div>
        <div class="PI-down" data-aos="fade-in">
          <div
            class="PI-info-inner"
            v-for="(item, index) in tanaman"
            :key="index"
          >
            <img :src="item.foto" alt="" />
            <div class="PI-inner-txt">
              <h2>{{ item.nama }}</h2>
              <a v-on:click="detail(item.nama)">Baca lebih lanjut >>></a>
            </div>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<script>
import konten from "../../services/konten.service";

export default {
  name: "Tanaman",
  data() {
    return {
      loading: true,
      tanaman: [],
    };
  },
  methods: {
    get() {
      konten
        .getKonten("tanaman")
        .then((res) => {
          this.tanaman = res.data.data;
          this.loading = false;
          // console.log(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    detail(nama) {
      nama = nama.toLowerCase().replace(" ", "_");
      this.$router.push("/agri/tanaman/" + nama);
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style scoped>
#PI {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 3em;
}

.load-enter-active,
.load-leave-active {
    transition: opacity .5s ease-in
}

.load-enter,
.load-leave-to {
    opacity: 0
}

.loading {
  position: fixed; /* or absolute */
  margin-top: -64px;
  margin-left: -64px;
  top: 50%;
  left: 50%;
  transition: display 0.5ms ease;
}

.loading img {
  max-width: 128px;
}

.PI-inner {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1180px;
}

.PI-inner .PI-up {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
}

.PI-inner .PI-up h1 {
  color: green;
  font-weight: 400;
  border-bottom: 1.5px solid green;
  width: 50%;
}

.PI-inner .PI-down {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 2em;
  padding: 1em 1em;
}

.PI-down .PI-info-inner {
  /* background-color:lawngreen; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 150px;
}

.PI-down .PI-info-inner img {
  height: 100%;
  width: 100%;
  max-width: 250px;
  object-fit: cover;
  border: 4px solid green;
  margin-right: 1em;
}

.PI-info-inner .PI-inner-txt {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.PI-info-inner .PI-inner-txt h2 {
  color: green;
  margin-bottom: 0.25em;
  font-weight: 400;
  font-size: 2rem;
}

.PI-info-inner .PI-inner-txt a {
  color: grey;
  text-decoration: none;
  font-size: 1.05rem;
  cursor: pointer;
}
@media screen and (max-width: 1013px){
  .PI-down .PI-info-inner img {
    max-width: 200px;
  }
}
@media screen and (max-width: 900px){
  .PI-inner .PI-up h1{
    width: 100%;
  }
  .PI-down .PI-info-inner img {
    max-width: 260px;
  }
  .PI-inner .PI-down {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    padding: 1em 1em;
  }
}
@media screen and (max-width: 540px){
  .PI-down .PI-info-inner img {
    max-width: 190px;
  }
  h2{
      font-size: 1.5rem !important;
  }
  a{
    font-size: 0.75rem !important;
  }
}
@media only screen and (max-width: 400px){
  .PI-down .PI-info-inner img {
    max-width: 150px;
  }
}
</style>
